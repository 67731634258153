import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import DiscountRowItem from './DiscountRowItem';

const defaultSort = (a, b) => a.priority - b.priority;

const AvailabilityDiscounts = ({ availabilityCategories, isTitleShown }) => {
  const { t } = useTranslation();
  const sortedAvailabilityCategories = availabilityCategories.sort(defaultSort);

  return (
    <Spacing size="S" vertical>
      {isTitleShown && <Text weight="bold">{t('search:trip_discount_title')}</Text>}
      <div>
        {sortedAvailabilityCategories.map((category) => {
          const categoryType = t('category', { context: category?.type });

          return (
            <DiscountRowItem
              key={categoryType}
              label={categoryType}
              availability={category?.availability}
              total={category?.total}
            />
          );
        })}
      </div>
    </Spacing>
  );
};

AvailabilityDiscounts.propTypes = {
  availabilityCategories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      availability: PropTypes.number.isRequired,
      priority: PropTypes.number.isRequired,
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ),
  isTitleShown: PropTypes.bool,
};

AvailabilityDiscounts.defaultProps = {
  isTitleShown: true,
};

export default AvailabilityDiscounts;
