import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab, Spacing } from '@reservamos/elements';
import prepareItineraryDetails from 'utils/trips/prepareItineraryDetails';
import prepareProviderDetails from 'utils/trips/prepareProviderDetails';
import PurchasePricing from 'components/purchase/PurchasePricing';
import TripItinerary from 'components/TripItinerary';
import i18n from 'i18next';
import ModalWithTheme from 'components/ModalWithTheme';
import AvailabilityDiscounts from 'components/search/AvailabilityDiscounts';
import wayIsOpenTicket from '../../utils/wayIsOpenTicket';

const propTypes = {
  features: PropTypes.object.isRequired,
  departs: PropTypes.object.isRequired,
  returns: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onClosePurchaseReview: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool,
};

/**
 * @param {Object} props - Object props
 * @param {object} props.departs - Object containing trip depart data.
 * @param {object} props.returns - Object containing trip return data.
 * @param {boolean} props.roundTrip - Indicates if is round trip.
 * @param {function} props.showModal - Function that executes modal display.
 * @param {boolean} props.visible - Indicates if modal should display.
 * @param {function} props.onClosePurchaseReview - Fires on closed modal to execute analytics trackers.
 * @param {boolean} props.isOpenTicket - Indicates if selected trip has an open ticket.
 * @returns {JSX.Element} A React component that renders the trip detail modal.
 */
class PurchaseReview extends Component {
  renderTripDetails(trip, title) {
    const { showModal } = this.props;
    const { transportType, fragments, tickets } = trip;
    const firstFragment = fragments[0];
    let { selectedSeats } = trip;
    let itinerary;
    let providerAmenities;

    if (transportType === 'mixed') {
      itinerary = prepareItineraryDetails(trip);
      providerAmenities = prepareProviderDetails(trip);
    } else {
      itinerary = prepareItineraryDetails(firstFragment);
      providerAmenities = prepareProviderDetails(firstFragment);
    }

    if (transportType === 'bus' && tickets.length > 0) {
      selectedSeats = firstFragment.tickets
        .map((ticket) => ({
          number: ticket.seat,
          category: ticket.category,
          firstName: ticket.firstName,
          lastName: ticket.lastName,
        }))
        .filter(({ number }) => !!number);
    }
    const [{ provider, services }] = providerAmenities;

    return (
      <TripItinerary
        title={title}
        trip={trip}
        total={trip.pricing.total}
        itinerary={itinerary}
        stopoverPlace={trip.stopoverPlace}
        providerAmenities={providerAmenities}
        showModal={showModal}
        headerDescription="total"
        selectedSeats={selectedSeats}
        isOpenTicket={wayIsOpenTicket(trip) === true}
        providerDetails={provider}
        providerServices={services}
        isPurchaseAttempt
      />
    );
  }

  renderItinerarySection(departs, title, roundTrip, returns) {
    return (
      <>
        <PurchasePricing />
        {this.renderTripDetails(departs, title)}

        {roundTrip ? this.renderTripDetails(returns, 'your_return_trip') : null}
      </>
    );
  }

  render() {
    const { features, departs, returns, roundTrip, visible, onClosePurchaseReview, isOpenTicket } =
      this.props;
    const availabilityCategories = departs?.fragments?.[0].passengerTypes ?? [];
    const title = roundTrip ? 'your_one_way_trip' : 'your_trip';
    if (!visible) return null;

    return (
      <ModalWithTheme
        title={isOpenTicket ? i18n.t('trip_details') : i18n.t('travel_itinerary')}
        responsiveSize="L"
        onCloseModal={onClosePurchaseReview}
      >
        {!features.SHOW_PURCHASE_REVIEW_TABS || availabilityCategories.length === 0 ? (
          <div className="purchase-review-section">
            {this.renderItinerarySection(departs, title, roundTrip, returns)}
          </div>
        ) : (
          <Tabs headerGrow active={0}>
            <Tab label={i18n.t('purchase:tab.itinerary')} iconType="route">
              {this.renderItinerarySection(departs, title, roundTrip, returns)}
            </Tab>
            <Tab label={i18n.t('purchase:tab.free_fares')} iconType="discountTag">
              <Spacing isResponsive size="L" vertical>
                <AvailabilityDiscounts
                  availabilityCategories={availabilityCategories}
                  isTitleShown={false}
                />
              </Spacing>
            </Tab>
          </Tabs>
        )}
      </ModalWithTheme>
    );
  }
}

PurchaseReview.propTypes = propTypes;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(PurchaseReview);
